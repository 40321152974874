<template>
	<div>
		<div class="ft20 cl-black cl-main ftw500">会员列表</div>

		<div class="mt20">
			<div class="form-search-box ">
				<a-form layout="inline">
					<a-form-item label="会员昵称">
						<a-input v-model="search.nick_name" placeholder="请输入会员昵称"></a-input>
					</a-form-item>

					<a-form-item label="会员手机号">
						<a-input v-model="search.mobile" placeholder="请输入会员手机号"></a-input>
					</a-form-item>

					<a-form-item label="会员等级">
						<div>
							<a-select style="width: 120px" placeholder="请选择" v-model="search.level_id">
								<a-select-option :value="-99">请选择</a-select-option>
								<a-select-option :value="0">普通会员</a-select-option>
								<a-select-option :value="item.level_id" v-for="(item,index) in level" :key="index">{{item.name}}</a-select-option>
							</a-select>
						</div>
					</a-form-item>
					<a-form-item label="会员生日">
						<a-radio-group v-model="search.birthday_type" >
						        <a-radio-button :value="1">
						          全部
						        </a-radio-button>
						        <a-radio-button :value="2">
						          本月
						        </a-radio-button>
								<a-radio-button :value="3">
								  下月
								</a-radio-button>
						  </a-radio-group>
					</a-form-item>
					<a-form-item>
						<a-button @click="searchAct" type="primary">查询</a-button>
						<a-button @click="cancelAct" class="ml10">取消</a-button>
					</a-form-item>
				</a-form>
			</div>
		</div>

		<div class="mt20 pd20 bg-w">
			<div>
				<a-button type="primary" icon="export" @click="exportMemberAct()">导出会员</a-button>
			</div>
			<div class="wxb-table-gray mt20">
				<a-table rowKey="member_id" :columns="columns" :pagination="pagination" @change="handleTableChange" :data-source="datas"
				 :loading="loading">
					<div class="flex alcenter center" slot="vip" slot-scope="record,index">
						<img v-if="record.face!=null" :src="record.face" class="member-index-face" />
						<img v-else src="../../../assets/image/woyaoyou_head_defult@2x.png" class="member-index-face" />
						<div class="ml5" style="text-align: left;">
							<div>{{record.nick_name}}</div>
							<div class="mt2">{{record.mobile}}</div>
						</div>
					</div>

					<div class="flex alcenter left" slot="pmember" slot-scope="pmember,record">
						{{pmember==null ? '暂无' : pmember.nick_name}}
					</div>

					<div class="flex alcenter left" slot="level" slot-scope="level,record">
						{{level==null ? '普通会员' : level.name}}
					</div>

          <div class="flex alcenter left" slot="mtype" slot-scope="type,record">
            {{type==0 ? '普通会员' : '长者类型'}}
          </div>

					<!-- <div class="flex alcenter center" slot="integral" slot-scope="integral,record">
						{{integral==null ? '0' : integral.remain_integral}}
					</div> -->


					<template slot="action" slot-scope="record,index">
						<div class="flex center">
							<a-dropdown placement="bottomRight">
								<span class="more-act">
									<i class="iconfont iconmore_gray"></i>
								</span>
								<a-menu slot="overlay">
<!--									<a-menu-item>-->
<!--										<a class="menu-act" href="javascript:;" @click="edit(record)">-->
<!--											<span>修改会员</span>-->
<!--										</a>-->
<!--									</a-menu-item>-->

									<a-menu-item>
										<a class="menu-act" href="javascript:;" @click="see(record)">
											<span>查看会员</span>
										</a>
									</a-menu-item>

<!--									<a-menu-item>-->
<!--										<a class="menu-act" href="javascript:;" @click="changePid(record)">-->
<!--											<span>修改上级用户</span>-->
<!--										</a>-->
<!--									</a-menu-item>-->

								</a-menu>
							</a-dropdown>
						</div>
					</template>
				</a-table>
			</div>
		</div>

		<a-modal title="修改会员" :width="400" :visible="visible"  @cancel="handleCancel" >
			<template slot="footer" >
				<a-button  key="back"  @click="handleCancel">
					取消
				</a-button>
				<a-button  key="submit" type="primary" @click="onSubmit" :loading="confirmLoading">
					修改
				</a-button>
			</template>
			<div>
			<a-form-item label="会员昵称">
					<a-input placeholder="请输入会员姓名" v-model="member.nick_name" :maxLength="20"></a-input>
			</a-form-item>
			<a-form-item label="会员等级">
				<a-select style="width: 120px" placeholder="请选择" v-model="member.level_id">
					<a-select-option :value="-99">请选择</a-select-option>
					<a-select-option :value="0">普通会员</a-select-option>
					<a-select-option :value="item.level_id" v-for="(item,index) in level" :key="index">{{item.name}}</a-select-option>
				</a-select>
			</a-form-item>
			</div>
		</a-modal>

		<div v-if="changePidVisibel">
			<change-pid :visible="changePidVisibel" :member_id="member_id" @cancel="cancelChangePid" @ok="okChangePid"></change-pid>
		</div>
	</div>
</template>

<script>
  import {listMixin} from '@/common/mixin/list';
  import WaterMark from '@/common/watermark.js'
	import changePid from './components/index/modal/changPid.vue';
	export default {
		mixins: [listMixin],
		components:{
			changePid
		},
		data() {
			return {
        waterMark: null,
				loading: false,
				visible:false,
				editloading:false,
				confirmLoading:false,
				changePidVisibel:false,
				pagination: {
					current: 1,
					pageSize: 10, //每页中显示10条数据
					total: 0,
				},
				search: {
					nick_name: '',
					mobile: "",
					level_id: -99,
					birthday_type:1
				},
				level:[],
				columns: [
					{title: '编号',dataIndex: 'member_id',align: 'center'},
					{title: '会员',key: 'vip',align: 'left',scopedSlots: {customRender: 'vip'}},
					{title: '会员类型',dataIndex: 'type',align: 'left',scopedSlots: {customRender: 'mtype'}},
					{title: '会员生日',dataIndex: 'birthday',align: 'left',ellipsis: true},
					{title: '积分',dataIndex: 'integral',align: 'right',ellipsis: true},
					{title: '注册时间',dataIndex: 'add_time_format',align: 'left',ellipsis: true},
					{title: '操作',key: 'action',align: 'center',scopedSlots: {customRender: 'action'}}
				],
				datas: [],
				member:{},
				member_id:0,
			}
		},
		methods: {
			getLists(){
				if(this.loading==true) return;
				this.loading=true;
				this.$http.api('platform/member/getMemberList',{
					nick_name:this.search.nick_name,
					mobile:this.search.mobile,
					level_id:this.search.level_id,
					birthday_type:this.search.birthday_type,
					limit:this.pagination.pageSize,
					page:this.pagination.current,
				}).then(res=>{
          this.pagination.total=res.total;
          this.pagination.showTotal=(total)=>"共"+res.total+"条";
					this.datas=res.list;
					this.level=res.level;
					this.loading=false;
				}).catch(res=>{
					this.loading=false;
				})
			},

			handleTableChange(pagination, filters, sorter) {
				this.pagination.current = pagination.current;
				this.getLists();
			},
			see(record) {
        this.$router.push('/memberAdmin/detail?id=' + record.member_id);
			},
			edit(record){
				this.member=record;
				this.visible=true;
			},
			handleCancel(){
				this.visible=false;
			},
			onSubmit(){
				if(this.confirmLoading==true) return;
				this.confirmLoading=true;
				this.$http.api('platform/member/saveNickName',{
					member_id:this.member.member_id,
					nick_name:this.member.nick_name,
					level_id :this.member.level_id,
				}).then(res=>{
					this.$message.success('修改成功',1,()=>{
						this.confirmLoading=false;
						this.visible=false;
						this.getLists();
					})
				}).catch(res=>{
					this.confirmLoading=false;
				})
			},

			exportMemberAct(){

			},

			/**
			 * 修改用户的上级
			 * @param {Object} record
			 */
			changePid(record){
				this.member_id=record.member_id;
				this.changePidVisibel=true;
			},
			cancelChangePid(){
				this.changePidVisibel=false;
			},
			okChangePid(){
				this.changePidVisibel=false;
				this.getLists();
			},

      // 设置水印
      showMark() {
        let userinfo = JSON.parse(localStorage.getItem('userinfo'));
        this.waterMark = new WaterMark({
          watermark_txt: userinfo.name, // 水印的内容
          watermark_x: 150, // 水印起始位置x轴坐标
          watermark_y: 150, // 水印起始位置Y轴坐标
          watermark_rows: 0, // 水印行数
          watermark_cols: 0, // 水印列数
          watermark_x_space: 100, // 水印x轴间隔
          watermark_y_space: 100, // 水印y轴间隔
          watermark_width: 150, // 水印宽度
          watermark_height: 80, // 水印长度
          watermark_angle: 315, // 水印倾斜度数
          monitor: false,
        });
      },
      // 隐藏水印
      hidden() {
        if (this.waterMark) {
          this.waterMark.remove()
        }
      }

		},
    mounted() {
      this.showMark();
      // 监听离开隐藏水印
      window.addEventListener("beforeunload", this.hidden);
    },
    // 销毁组件
    destroyed() {
      this.hidden()
    }
	}
</script>

<style>
	.member-index-face {
		width: 44px;
		height: 44px;
		border-radius: 44px;
		box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.04);
		border: 2px solid #FFFFFF;
	}

	.member-index-action {
		width: 32px;
		height: 32px;
		border-radius: 32px;
		background: #FFFFFF;
		border: 1px solid #EBEDF5;
		text-align: center;
		line-height: 32px;
	}
</style>
